import {Injectable} from '@tanbo/vue-di-plugin';
import {Http} from '@zhfe/common-library';
import {ReportService} from '@/services/report.service';

interface ExchangeRequestParams {
  code: string
}

export interface PageDetail {
  name: string
  img_url: string
  is_diamond: boolean
}

export interface VipTypeList {
  vip_type: string // VIP类型
  period: number // 添加的周期长度，和period_unit连用
  period_unit: string // 周期单位: second,hour,day,month,season,year
  page_detail: PageDetail // 展示的相关信息
}

export interface ExchangeType {
  type: string // 兑换码类型
  name: string // 兑换码类型名
  vip_type_list: VipTypeList[] // 添加的vip类型
}

export interface VipInfoList {
  vip_type: string // vip的类型
  expired_at: string // 有效期
  page_detail: PageDetail // 展示的相关信息
}

export interface ExchangeResponseData {
  exchange_type: ExchangeType // ExchangeType 兑换码类型
  vip_info_list: VipInfoList[] // 只返回兑换后，兑换码影响的用户的vip信息
}

@Injectable()
export class ExchangeHomeService {
  constructor(
    private http: Http,
    private reporter: ReportService
  ) {
  }

  report = this.reporter.makeReporter('infockeniu_vip_exchangecode:176 version:int product_id:int act:int click:int exchangecode:string errcode:int status:int', {
    act: 2,
    click: 0,
    exchangecode: '',
    errcode: 0,
    status: 0
  })

  /**
   * @description 请求开始兑换
   */
  requestStartExchange (params: ExchangeRequestParams) {
    return this.http.post<ExchangeResponseData>('/exchangecode/exchange', params)
  }
}
