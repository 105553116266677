
import {defineComponent, onMounted, reactive} from 'vue';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import {ExchangeHomeService} from '@/views/exchange/_services/exchange-home.service';
import {InterestsService} from '@/views/interests/interests.service';

// 兑换码成功页
export default defineComponent({
  name: 'ExchangeSuccess',
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['step'],
  setup (props, { emit }) {
    const injector = useReflectiveInjector()
    const service = injector.get(ExchangeHomeService)
    const interest = injector.get(InterestsService)
    const superMember = ['keniu_normal', 'keniu_diamond']
    const viewModel = reactive({
      /** 我的特权列表 */
      myTequanList: [
        { pid: '102', iconClass: 'computer_icon', label: '可牛电脑助手', num: 0, status: false, contain: [...superMember, 'pcmgr_normal', 'pcmgr_diamond'] },
        { pid: '103', iconClass: 'pdf_icon', label: '可牛PDF', num: 0, status: false, contain: [...superMember, 'pdf_normal', 'pdf_diamond'] },
        { pid: '100', iconClass: 'kantu_icon', label: '可牛看图', num: 0, status: false, contain: [...superMember, 'fastpic_normal', 'fastpic_diamond'] },
        { pid: '105', iconClass: 'video_icon', label: '可牛视频转换', num: 0, status: false, contain: [...superMember, 'video_normal', 'video_diamond'] }
      ],
      /** 兑换详情 */
      exchangeInfo: props.info
    })

    onMounted(() => {
      initInterestNum()
    })

    handleSuccessDetail()
    handleSoftwareList()

    /**
     * @description 初始化权益数量
     */
    function initInterestNum () {
      interest.requestInterest({ os: '' }).then(res => {
        viewModel.myTequanList.forEach(p => {
          (res.app_interest_list || []).forEach(c => {
            if (p.pid === c.app_id) {
              p.num = c.interest_list.length
            }
          })
        })
      })
    }

    /**
     * @description 点击确定
     */
    function clickConfirm () {
      // 前往软件推荐步骤页面
      emit('step', 'recommend')
      service.report({ click: 6 })
    }

    /**
     * @description 处理兑换成功详情
     */
    function handleSuccessDetail () {
      const obj = viewModel.exchangeInfo
      const dateEnum = {
        second: '分钟',
        hour: '小时',
        day: '天',
        month: '月',
        season: '季节',
        year: '年'
      }

      const vipTypeList = obj?.exchange_type?.vip_type_list[0]

      const cycle = vipTypeList?.period // 兑换码周期
      const company = vipTypeList?.period_unit // 兑换码周期单位
      const is_diamond = viewModel.exchangeInfo?.vip_info_list[0]?.page_detail?.is_diamond

      viewModel.exchangeInfo.exchangeDateDesc = is_diamond ? '永久' : cycle + dateEnum[company]
    }

    /**
     * @description 根据会员类别处理需要高亮的软件
     */
    function handleSoftwareList () {
      const vipType = viewModel.exchangeInfo?.vip_info_list[0].vip_type

      viewModel.myTequanList.forEach(e => {
        if (e.contain.includes(vipType)) {
          e.status = true
        }
      })
    }

    return {
      viewModel,
      clickConfirm,
    }
  }
})

