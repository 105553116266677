
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import { ToastService } from '@/services/toast.service';
import { MyExchangeListService } from '@/views/exchange/_services/my-exchange-list.service';

// 我的兑换码列表
export default defineComponent({
  name: 'MyExchangeList',
  emits: ['step'],
  setup (_, { emit }) {
    const injector = useReflectiveInjector([MyExchangeListService])
    const toast = injector.get(ToastService)
    const service = injector.get(MyExchangeListService)
    const viewModel = reactive({
      list: [],
      /** 是否选中多选框 */
      checked: false,
    })

    onMounted(() => {
      initMyExchangeList()
    })

    /** 根据状态展示兑换码列表 */
    const exchangeList = computed(() => {
      if (viewModel.checked) {
        return viewModel.list.filter(e => e.status === 'a')
      }

      return viewModel.list
    })

    /**
     * @description 初始化我的兑换码列表
     */
    function initMyExchangeList () {
      const params = {}

      service.requestMyExchangeList(params).then(res => {
        viewModel.list = res
      })
    }

    /**
     * @description 复制兑换码
     */
    function copyExchange (str: string) {
      if (!str) {
        return
      }

      const aux = document.createElement('input')
      aux.setAttribute('value', str)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)
      toast.showToast({ message: '复制成功' })
    }

    /**
     * @description 切换多选框状态
     */
    function toggleCheckboxStatus () {
      viewModel.checked = !viewModel.checked
    }

    /**
     * @description 返回兑换码首页
     */
    function goBack () {
      emit('step', 'home')
    }

    /**
     * @description 关闭兑换码窗口
     */
    function closeExchangeModel () {
      window.close()
    }

    return {
      viewModel,
      exchangeList,
      goBack,
      copyExchange,
      closeExchangeModel,
      toggleCheckboxStatus
    }
  }
})

