
import {defineComponent, reactive, onUnmounted, onMounted} from 'vue';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import {BridgeService} from '@/services/bridge.service';
import {UserService} from '@/services/user.service';
import {ExchangeHomeService} from '@/views/exchange/_services/exchange-home.service';
import { ToastService } from '@/services/toast.service';

export default defineComponent({
  name: 'ExchangeHome',
  emits: ['step', 'errCode', 'success'],
  setup (_, { emit }) {
    const injector = useReflectiveInjector()
    const bridge = injector.get(BridgeService)
    const user = injector.get(UserService)
    const service = injector.get(ExchangeHomeService)
    const toast = injector.get(ToastService)
    const viewModel = reactive({
      code: '',
      isLogin: user.isLogin
    })
    const sub = user.onUserInfoChanged.subscribe(() => {
      const field = ['isLogin']

      field.forEach(e => (viewModel[e] = user[e]))
    })

    onMounted(() => {
      service.report({ act: 1 })
    })

    onUnmounted(() => {
      sub.unsubscribe()
    })

    /**
     * @description 立即兑换
     */
    function goExchange () {
      if (!viewModel.isLogin) {
        bridge.otherUserLogin()
        service.report({ click: 1, status: 3 })
        return
      }

      if (!viewModel.code) {
        toast.showToast({ message: '请先输入兑换码' })
        service.report({ click: 1, status: 3 })
        return
      }

      const params = {
        code: viewModel.code
      }

      service.requestStartExchange(params).then((res) => {
        // 传递成功页面所需的信息
        emit('success', res)
        // 前往兑换成功步骤页面
        emit('step', 'success')
        // 通知客户端刷新用户状态&刷新会员中心
        bridge.refreshUserInfo()
        bridge.refreshPage({ type: 'include', pages: ['main'] })

        service.report({ click: 1, status: 1, exchangecode: res?.vip_info_list[0]?.vip_type })
      }).catch(err => {
        const errCode = err.responseCommon.ret

        service.report({ click: 1, status: 2, errcode: errCode })
        emit('errCode', errCode)
      })
    }

    return {
      viewModel,
      goExchange
    }
  }
})

