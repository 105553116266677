
import {defineComponent, reactive} from 'vue'
import ExchangeHome from './_components/exchange-home.vue'
import ExchangeHead from './_components/exchange-head.vue'
import ExchangeSuccess from './_components/exchange-success.vue'
import SoftWareRecommend from './_components/software-recommend.vue'
import MyExchangeList from './_components/my-exchange-list.vue'
import FailModal from './_components/fail-modal.vue'
// import OfficialCode from './_components/official-code.vue'
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import {ExchangeHomeService} from '@/views/exchange/_services/exchange-home.service';

// 兑换码公共头部
export default defineComponent({
  name: 'Exchange',
  components: {
    ExchangeHome,
    ExchangeHead,
    ExchangeSuccess,
    FailModal,
    // OfficialCode,
    MyExchangeList,
    SoftWareRecommend,
  },
  setup () {
    useReflectiveInjector([ExchangeHomeService])

    const viewModel = reactive({
      /** 进度 home=首页 success=成功 recommend=推荐 list:我的兑换码列表 */
      step: 'home',
      /** 兑换错误状态码 */
      errCode: 0,
      /** 失败对话框显示 */
      failModalVisible: false,
      /** 公众号关注显示 */
      officialCodeVisible: false,
      /** 兑换成功信息 */
      exchangeSuccessInfo: {}
    })

    /**
     * @description 打开公众号关注对话框
     */
    function openOfficialCode () {
      viewModel.officialCodeVisible = true
    }

    /**
     * @description 更新步骤
     */
    function updateStep (step: string) {
      viewModel.step = step
    }

    /**
     * @description 处理兑换成功
     */
    function handleExchangeSuccess (obj) {
      viewModel.exchangeSuccessInfo = obj
    }

    /**
     * @description 处理兑换码错误
     */
    function handleExchangeErrCode (errCode: number) {
      viewModel.errCode = errCode
      viewModel.failModalVisible = true
    }

    return {
      viewModel,
      updateStep,
      openOfficialCode,
      handleExchangeErrCode,
      handleExchangeSuccess,
    }
  }
})
