
import {defineComponent, reactive, watch} from 'vue';
import {BridgeService} from '@/services/bridge.service';
import {UserService} from '@/services/user.service';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import {ExchangeHomeService} from '@/views/exchange/_services/exchange-home.service';

export default defineComponent({
  name: 'ExchangeHead',
  props: {
    step: {
      type: String,
      default: 'home'
    }
  },
  emits: ['step'],
  setup (props, { emit }) {
    const injector = useReflectiveInjector()
    const service = injector.get(ExchangeHomeService)
    const bridge = injector.get(BridgeService)
    const user = injector.get(UserService)

    const viewModel = reactive({
      step: props.step,
      isLogin: user.isLogin,
      nickname: user.nickname,
    })

    watch(() => props.step, newVal => {
      viewModel.step = newVal
    })

    user.onUserInfoChanged.subscribe(() => {
      const field = ['isLogin', 'nickname']

      field.forEach(e => (viewModel[e] = user[e]))
    })

    /**
     * @description 切换账号
     */
    function toggleAccount () {
      service.report({ click: 3 })
      if (viewModel.isLogin) {
        user.onLogout.next()
      }
      // bridge.exit()

      bridge.otherUserLogin()
    }

    /**
     * @description 点击我的兑换码
     */
    function clickMyExchange () {
      if (!viewModel.isLogin) {
        bridge.otherUserLogin()
        // return
      }

      // 前往我的兑换码列表步骤页面
      emit('step', 'list')
    }

    /**
     * @description 关闭兑换码窗口
     */
    function closeExchangeModel () {
      window.close()
    }

    return {
      viewModel,
      toggleAccount,
      clickMyExchange,
      closeExchangeModel
    }
  }
})

