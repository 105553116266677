import {Injectable} from '@tanbo/vue-di-plugin';
import {Http} from '@zhfe/common-library';

@Injectable()
export class MyExchangeListService {
  constructor(private http: Http) {
  }

  /**
   * @description 请求我的兑换码列表
   */
  requestMyExchangeList (params: any) {
    return this.http.post('/baoquvip/payv2/ali/native', params)
  }
}
