
import {computed, defineComponent, reactive} from 'vue';

// 兑换码成功页
export default defineComponent({
  name: 'FailModal',
  props: {
    errCode: {
      type: Number,
      default: 0
    }
  },
  emits: ['openOfficialCode', 'close'],
  setup (props, { emit }) {
    const err_code_obj = {
      4455001: '经检测无该兑换码，请重新输入。',
      4455002: '该兑换码已过期，无法兑换，请重新输入。',
      4455003: '该兑换码已兑换，请重新输入。',
      4455004: '该兑换码不可用，请重新输入。',
      4455005: '您已使用过兑换码，暂时无法叠加使用。',
      4455007: '您已拥有该会员特权，暂时无法使用兑换码。',
    }

    const viewModel = reactive({
      errCode: props.errCode as number
    })

    const error_text = computed(() => err_code_obj[viewModel.errCode] || '兑换码异常')

    /**
     * @description 关闭失败对话框
     */
    function closeFailModel () {
      emit('close')
    }

    /**
     * @description 打开公众号关注对话框
     */
    function openOfficialCode () {
      emit('openOfficialCode')
    }

    return {
      error_text,
      closeFailModel,
      openOfficialCode
    }
  }
})
