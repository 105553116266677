
import {defineComponent, reactive} from 'vue';
import {useReflectiveInjector} from '@tanbo/vue-di-plugin';
import {ExchangeHomeService} from '@/views/exchange/_services/exchange-home.service';
// 软件推荐页
export default defineComponent({
  name: 'SoftwareRecommend',
  emits: ['step'],
  setup (_, { emit }) {
    const injector = useReflectiveInjector()
    const service = injector.get(ExchangeHomeService)
    const viewModel = reactive({
      softwareList: [
        {
          iconClass: 'computer_icon',
          label: '可牛电脑助手',
          desc: '一站式服务，您的智能电脑管家'
        },
        {
          iconClass: 'kantu_icon',
          label: '可牛看图',
          desc: '全新引擎，百兆大图秒开'
        },
        {
          iconClass: 'pdf_icon',
          label: '可牛PDF',
          desc: '核心自研_PDF高效办公方案'
        },
        {
          iconClass: 'video_icon',
          label: '可牛视频转换',
          desc: '全新引擎 百兆大图秒开'
        },
      ],
    })

    /**
     * @description 返回兑换码首页
     */
    function goBack () {
      emit('step', 'home')
    }

    /**
     * @description 打开官网
     */
    function openWebsite () {
      service.report({ click: 5 })
      window.open('https://keniu.com/')
    }

    return {
      viewModel,
      goBack,
      openWebsite
    }
  }
})

